import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import ReactGA from "react-ga4";

import Home from "./components/Home"
import Qr001 from "./components/Qr001"
import Register from "./components/Register"
import RegisterSuccess from "./components/Register_Success"
import Login from "./components/Login"
import ForgotPassword from "./components/Forgot_Password"
import ForgotPasswordSent from "./components/Forgot_Password_Sent"
import ResetPassword from "./components/Reset_Password"
import Member from "./components/Member"

import AdminLogin from "./components/Admin_Login"
import AdminLogout from "./components/Admin_Logout"
import AdminDashboard from "./components/Admin_Dashboard"
import AdminPlayers from "./components/Admin_Players"
import Admin_Players_top10_f1 from "./components/Admin_Players_top10_f1"
import Admin_Players_top10_fornite from "./components/Admin_Players_top10_fornite"
import AdminStages from "./components/Admin_Stages"
import AdminStarwarsCodes from "./components/Admin_Starwars_Codes"
import AdminEmailList from "./components/Admin_Email_List"
import AdminTeams from "./components/Admin_Teams"
import AdminStagesAdd from "./components/Admin_Stages_Add"
import AdminStagesEdit from "./components/Admin_Stages_Edit"
import AdminPlayerEdit from "./components/Admin_Player_Edit"
import AdminPlayerPuanEdit from "./components/Admin_Player_Puan_Edit"

ReactGA.initialize("G-LVY192JDFM");

function App() {
  return (
    <div className="App">
        
    <BrowserRouter>
      <Routes>
        <Route  >

          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register_success" element={<RegisterSuccess />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/forgotpassword_sent" element={<ForgotPasswordSent />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route path="/member" element={<Member />} />

          <Route path="/admin_otesi" element={<AdminLogin />} />
          <Route path="/admin_otesi/dashboard" element={<AdminDashboard />} />
          <Route path="/admin_otesi/logout" element={<AdminLogout />} />
          <Route path="/admin_otesi/players/:id" element={<AdminPlayers />} />

          <Route path="/admin_otesi/top10_f1/:id" element={<Admin_Players_top10_f1 />} />
          <Route path="/admin_otesi/top10_fortnite/:id" element={<Admin_Players_top10_fornite />} />



          <Route path="/admin_otesi/stages" element={<AdminStages />} />
          <Route path="/admin_otesi/starwars_codes" element={<AdminStarwarsCodes />} />
          <Route path="/admin_otesi/email_list" element={<AdminEmailList />} />
          <Route path="/admin_otesi/teams" element={<AdminTeams />} />
          <Route path="/admin_otesi/add_stage" element={<AdminStagesAdd />} />
          <Route path="/admin_otesi/stage_edit/:id" element={<AdminStagesEdit />} />
          <Route path="/admin_otesi/player_edit/:playerId/:stageId" element={<AdminPlayerEdit />} />
          <Route path="/admin_otesi/player_puan_edit/:id/:stageId" element={<AdminPlayerPuanEdit />} />



          <Route path="/qr001" element={<Qr001 />} />


        </Route>
      </Routes>
    </BrowserRouter>
    <ToastContainer/>
  </div>
  );
}

export default App;
