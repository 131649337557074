
import {  useState , useEffect, Fragment} from 'react'
import axios from 'axios'

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import { TbCircle,TbCircleCheck } from "react-icons/tb";




function Admin_Players_top10_f1() {
  const navigate = useNavigate();
  let { id } = useParams();

  
  const [players, setPlayers] = useState([]);
  const [stage, setStage] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [toplam, setToplam] = useState(0);
  const [score_checked, setscore_checked] = useState(false);

  const [keyword, setkeyword] = useState("");
  const handleKeyword = (e) => {
    setPage(0)
    setkeyword(e.target.value)
}



  useEffect(() => {

    getStageDetails();
    

  },[]);

  useEffect(() => {

    getPlayers();

  },[page]);

  const getStageDetails = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_stage/'+id).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
        setStage(response.data.stage)
      }
    });
  }

  
  const getPlayers = () => {



    axios.get(process.env.REACT_APP_API_URL+'admin_players_top10_f1/'+id).then(function (response) {
      if(response.data.result === "Success"){

        var gg = [];
        response.data.players.forEach(element => {
          if(element.player_details != null){
            gg.push(element)
          }
        });
        setPlayers(gg)


        setToplam(response.data.total)
      }
    });
  }





    return (
      <>
      <div>
        <AdminLeft page={"players"} />

        <div className="lg:pl-72">

          <AdminHeader page="players" />

          <main className="p-8">

            <div className='flex items-center space-x-10'>
              <div className='flex-1'>
                <div className='font-medium text-3xl '>{stage?.name}</div>
                <div className='font-medium text-xl'>TOP 10 - F1</div>
              </div>  

            </div>
            

            <table className="min-w-full divide-y mt-8 divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" width={50} className="p-2 text-left font-semibold text-gray-900 ">#</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Ad Yaş, Nickname</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Email Adresi</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Telefon</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Takım</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">F1 Süre</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {players.map((q,index) => (
                  <tr key={index}>

                    <td className="p-2 text-left  text-gray-900">{(page*20) + index+1}</td>
                    <td className=" p-2 text-left  text-gray-900">
                      <div className='font-semibold'>{q?.player_details?.name} - {q?.player_details?.age}</div>
                      <div className='text-slate-500'>{q?.player_details?.nickname}</div>
                    </td>
                    <td className=" p-2 text-left  text-gray-900">{q?.player_details?.email}</td>
                    <td className=" p-2 text-left  text-gray-900">{q?.player_details?.phone}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.team}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.f1_toplam}</td>

                    
                  </tr>
                ))}
              </tbody>
            </table>

           



            
          </main>
        </div>
      </div>


      </>
    );
}

  export default Admin_Players_top10_f1;