
import {  useState , useEffect} from 'react'
import axios from 'axios'

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess, date_tr} from '../components/commonFuncs'
import { useNavigate,} from 'react-router-dom';

import {PlusIcon, TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline'



function AdminStages() {
  const navigate = useNavigate();
  
  const [etaps, setEtaps] = useState([]);
  const [pass, setPass] = useState("");

  useEffect(() => {
    checkAdmin();

    getEtaps();

  },[]);

  const checkAdmin  = () => {

    let data = {
      token : localStorage.getItem('otesi_admin_token')
    };

    axios.post(process.env.REACT_APP_API_URL+'admin_check',JSON.stringify(data)).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
      }else{
        localStorage.removeItem('otesi_admin_token')
        navigate('/')
      }
    });
  }
  const getEtaps  = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_stages').then(function (response) {
      if(response.data.result === "Success"){
        setEtaps(response.data.stages)
      }
    });
  }



  const deleteIt = (e) => {

    let data = {
      token : localStorage.getItem('otesi_admin_token'),
      id : e.id
    };

    axios.post(process.env.REACT_APP_API_URL+'admin_delete_stage',JSON.stringify(data) ).then(function (response) {
        if(response.data.result === "Success"){
          showSuccess("Etap başarıyla silindi!");
          getEtaps();
        }
    });
    
  }

  const goToStageAdd = () => {
    navigate('/admin_otesi/add_stage')
  }
  const kodgonder = (id) => {
    let data = {
      token : localStorage.getItem('otesi_admin_token'),
      id : id
    };
    axios.post(process.env.REACT_APP_API_URL+'admin_add_to_emails',JSON.stringify(data) ).then(function (response) {
        if(response.data.result === "Success"){
          showSuccess("Katılımcılar email listesine eklendi!");
        }
    });
  }



    return (
      <>
      <div>
        <AdminLeft page={"stages"} />

        <div className="lg:pl-72">

          <AdminHeader  />

          <main className="p-8 ">
            <div className="flex">
              <div className='font-medium text-3xl flex-1'>Etaplar</div>
              <button onClick={goToStageAdd} className='bg-slate-800 hover:bg-purple-700  p-2 rounded-full text-white'>
                <PlusIcon className='w-6' strokeWidth={2}   />
              </button>
            </div>
            <table className="min-w-full divide-y mt-8 divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" width={50} className="p-2 text-left font-semibold text-gray-900 ">#</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Etap Adı</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Email Gönder</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Etap Tarihi</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Katılımcılar</th>
                  <th scope="col" className=" py-3.5 pl-3 pr-4 sm:pr-0"></th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {etaps.map((q,index) => (
                  <tr key={index}>

                    <td className="p-2 text-left  text-gray-900">{ + index+1}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.name}</td>
                    <td className=" p-2 text-left  text-gray-900">
                      <a href="#" onClick={() => kodgonder(q.id) } className="bg-blue-600 text-sm text-white rounded-md px-2 py-1 ">
                        Katılımcılara Kod Gönder
                      </a>
                    </td>
                    <td className=" p-2 text-left  text-gray-900">{date_tr(q.dates)}</td>
                    <td className="p-2 text-left  text-gray-900">
                    <a href={"/admin_otesi/players/" + q.id  }  className="text-white px-2 py-1 rounded-md bg-purple-600 me-5">
                      {q.players}
                    </a></td>

                    <td className=" p-2 text-left  text-gray-900">

                      <a href={"/admin_otesi/top10_f1/" + q.id  }  className="text-white px-2 py-1 rounded-md bg-orange-500 me-5"> Top10 - F1</a>
                      <a href={"/admin_otesi/top10_fortnite/" + q.id  }  className="text-white px-2 py-1 rounded-md bg-orange-500 me-5"> Top10 - Fortnite</a>

                    </td>

                    <td  className="p-2 flex justify-end">
                      <a href={"/admin_otesi/stage_edit/" + q.id}  className="text-indigo-600 me-5">
                        <PencilSquareIcon  className='w-6' />
                      </a>
                      <a href="#" onClick={() => { if (window.confirm('Silmek istediğinize emin misiniz?')) deleteIt(q)   } } className="text-red-600 ">
                        <TrashIcon className='w-6' />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </div>
      </div>


      </>
    );
}

  export default AdminStages;