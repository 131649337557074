
import {  useState , useEffect} from 'react'
import axios from 'axios'

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess, date_tr} from '../components/commonFuncs'
import { useNavigate,} from 'react-router-dom';

import {PlusIcon, TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline'



function AdminEmailList() {
  const navigate = useNavigate();
  
  const [etaps, setEtaps] = useState([]);
  const [pass, setPass] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(50);
  const [toplam, setToplam] = useState(0);


  useEffect(() => {
    checkAdmin();

    getCodes();

  },[]);

  useEffect(() => {

    getCodes();

  },[page]);

  const checkAdmin  = () => {

    let data = {
      token : localStorage.getItem('otesi_admin_token')
    };

    axios.post(process.env.REACT_APP_API_URL+'admin_check', JSON.stringify(data)).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
      }else{
        localStorage.removeItem('otesi_admin_token')
        navigate('/')
      }
    });
  }
  const getCodes  = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_email_list?limit='+limit+'&page='+page).then(function (response) {
      if(response.data.result === "Success"){
        console.log(response.data.emails);
        setEtaps(response.data.emails)
        setToplam(response.data.total)
      }
    });
  }

  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }

 


    return (
      <>
      <div>
        <AdminLeft page={"email_list"} />

        <div className="lg:pl-72">

          <AdminHeader  />

          <main className="p-8 ">
            <div className="flex">
              <div className='font-medium text-3xl flex-1'>Giden Emailler</div>
              
            </div>
            <table className="min-w-full divide-y mt-8 divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" width={50} className="p-2 text-left font-semibold text-gray-900 ">#</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Email</th>
                  <th scope="col" className="p-2 text-left">Kod</th>
                  <th scope="col" className="p-2 text-left">Stage</th>
                  <th scope="col" className="p-2 text-left">Gönderildi mi?</th>
                  <th scope="col" className="p-2 text-left">Tarih</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {etaps.map((q,index) => (
                  <tr key={index}>

                    <td className="p-2 text-left  text-gray-900">{(page*50) + index+1}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.email}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.code}</td>
                    <td className=" p-2 text-left  text-gray-900">{
                      q.stages.map((rr,index2) => (
                          <div key={index2}>
                            {rr.stage_name} - {rr.toplam}
                          </div>
                      ))  
                    }</td>

                    <td  className="p-2 text-left">
                      {q.is_sent === "1" ? "Gönderildi" : ""}
                    </td>
                    <td className=" p-2 text-left  text-gray-900">{q.tarih !== "0" && timeConverter(q.tarih)}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='mt-10 border-t p-2 flex'>
              <div className='flex-1'>toplam {toplam} email</div>
              <div className='flex space-x-2'>
              {[...Array(Math.ceil(toplam/limit)).keys()].map((i,index) => (
                    <button key={i} onClick={()=>setPage(i)}  className={(page === i ? 'bg-slate-700' : 'bg-slate-400') + ' text-white  px-2 py-1 rounded-lg '}>{i+1}</button>
                  ))}
              </div>
            </div>


          </main>
        </div>
      </div>


      </>
    );
}

  export default AdminEmailList;